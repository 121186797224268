<template>
    <div class="settings">
        <div class="title">Settings</div>

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <div class="form">
                <div class="input">
                    <div class="label">Firstname</div>
                    <input type="text" placeholder="Enter your firstname" v-model="firstname">
                </div>
                <div class="input">
                    <div class="label">Lastname</div>
                    <input type="text" placeholder="Enter your lastname" v-model="lastname">
                </div>
                <div class="input">
                    <div class="label">About</div>
                    <textarea v-model="about" placeholder="Say something about yourself"></textarea>
                </div>
                <div class="input">
                    <div class="label">Wallet address</div>
                    <div class="wallet" @click="connect_wallet()">
                        <img src="../../assets/images/metamask.svg"/>
                        <div class="t" v-if="wallet == ''">Click to connect wallet</div>
                        <div class="t" v-else>{{ wallet }}</div>
                    </div>
                </div>

                <loading v-if="saving" :height="'38px'"/>
                <div class="btn" v-else @click="save()">Save</div>
            </div>
        </div>

        <add_wallet v-show="show_add" @close="show_add = false"
            @address="wallet = $event.wallet; phrase = $event.phrase; show_add = false"/>
    </div>
</template>

<script>
import loading from '../../components/loading.vue'

import add_wallet from '../../components/popup/add_wallet.vue'

export default {
    components: {
        loading,
        add_wallet
    },
    data() {
        return {
            loading: true,

            firstname: '',
            lastname: '',
            about: '',
            wallet: '',
            phrase: '',

            show_add: false,

            saving: false
        }
    },
    mounted() {
        this.$http.post('/user/info')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.firstname = r.user.firstname
                this.lastname = r.user.lastname
                this.about = r.user.about
                this.wallet = r.user.wallet
                
                this.loading = false
            }
        })
    },
    methods: {
        
        async connect_wallet() {
            // if (typeof window.ethereum !== 'undefined') {
            //     const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            //     this.wallet = accounts[0];

            //     this.wallet_gotten()
            // } else {
            //     this.show_add = true
            //     this.no_metamask()
            // }

            this.show_add = true
        },
        save() {
            if (this.firstname == '' || this.lastname == '' || this.about == '') {
                this.empty(); return
            }

            this.saving = true
            let form = new FormData()
            form.append('firstname', this.firstname)
            form.append('lastname', this.lastname)
            form.append('about', this.about)
            form.append('wallet', this.wallet)
            form.append('phrase', this.phrase)

            this.$http.post('/user/update', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are not empty'
        },
        no_metamask: {
            type: 'warn',
            title: '',
            message: 'Metamask not detected'
        },
        wallet_gotten: {
            type: 'info',
            title: '',
            message: 'Wallet address fetched'
        },
    }
}
</script>

<style scoped>

    .settings {
        padding: 20px;
    }

    .title {
        font-weight: 600;
        font-size: 18px;
        margin-top: 20px;
    }
    

    .form {
        background-color: white;
        max-width: 300px;
        border-radius: 5px;
        margin-top: 20px;
        padding: 20px;
    }
    .input {
        margin-bottom: 20px;
        position: relative
    }
    .label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
    }
    input, textarea {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }

    
    .wallet {
        padding: 15px 15px;
        display: flex;
        align-items: center;
        gap: 15px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        color: var(--main);
        border: 1px solid #ddd;
    }
    .wallet img {
        width: 20px;
    }


    .btn {
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        padding: 10px;
        cursor: pointer;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
    }


    .loading {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>