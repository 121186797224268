<template>
    <div class="terms">
        <headerlayout class="h"/>

        <div class="main">
            <div class="title">Terms and Conditions</div>
            <div class="text">
                Acceptance of Terms: By accessing or using digivaultmarket, 
                you acknowledge that you have read, understood, and agree to be bound by these 
                Terms and Conditions. If you do not agree to these Terms, please refrain 
                from using the Website.
            </div>

            <div class="text">
                Intellectual Property: All content on the Website, including but not limited to text, 
                graphics, images, logos, and software, is the intellectual property of the Website owner 
                and is protected by applicable copyright, trademark, and other intellectual property 
                laws. You may not use, reproduce, distribute, modify, or create derivative works of 
                any content without prior written permission from the Website owner.
            </div>

            <div class="text">
                NFT Sales: The Website facilitates the sale of non-fungible tokens (NFTs) by individual 
                sellers ("Sellers"). The Website acts as a marketplace, allowing Sellers to list and 
                sell their NFTs to interested buyers. The Website does not own or endorse any NFTs 
                listed by Sellers.
            </div>

            <div class="text">
                Seller Responsibilities: Sellers are solely responsible for the accuracy of the 
                information provided when listing their NFTs on the Website. This includes but is not 
                limited to descriptions, pricing, and accompanying media. Sellers must ensure that 
                they have the necessary rights to sell the NFTs they list.
            </div>

            <div class="text">
                Buyer Responsibilities: Buyers are responsible for conducting due diligence before 
                purchasing any NFTs on the Website. This includes verifying the authenticity and 
                ownership rights associated with the NFTs. The Website is not liable for any disputes 
                or issues arising from the sale and purchase of NFTs.
            </div>

            <div class="text">
                Transaction Fees: The Website charges a transaction fee for each NFT sale, which is a 
                percentage of the final sale price. The specific fee amount will be clearly communicated 
                to the Seller during the listing process. The Seller agrees to pay the transaction 
                fee to the Website upon successful completion of the sale.
            </div>

            <div class="text">
                Gas Fees: Sellers are responsible for paying the necessary gas fees associated with 
                listing, selling, and transferring NFTs on the Website. Gas fees are transaction fees 
                on the blockchain network and may vary depending on network congestion and other factors. 
                Sellers should consider the gas fees when determining the sale price of their NFTs.
            </div>

            <div class="text">
                Withdrawal Fees: Sellers are responsible for paying the necessary withdrawal fees. 
                The withdrawal fee is for a withdrawal code which will be sent to the seller. This is 
                for making our platform safe (Note: withdrawal fee are refunded after 24 hours)
            </div>

            <div class="text">
                Limited Liability: The Website and its owner shall not be liable for any direct, 
                indirect, incidental, consequential, or exemplary damages arising from the use of the 
                Website or any NFT transaction conducted through the Website. This includes, but is not 
                limited to, loss of data, profits, or business interruption.
            </div>

            <div class="text">
                Modification of Terms: The Website owner reserves the right to modify these Terms at 
                any time without prior notice. It is the responsibility of users to review the Terms 
                periodically for any changes. Continued use of the Website after the posting of changes 
                constitutes acceptance of the modified Terms.
            </div>
        </div>

        <footerlayout/>
    </div>
</template>

<script>

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

export default {

    components: {
        headerlayout, footerlayout,
    },
    mounted() {
        window.scroll(0, 0)
    }
}
</script>

<style scoped>

    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    
    .main {
        max-width: 1100px;
        margin: 0 auto;
        padding: 30px 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }
    .text {
        margin-bottom: 30px;
        margin-top: 10px;
        line-height: 25px;
    }
</style>