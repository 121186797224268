<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Withdraw</div>
            <div class="main">
                <div class="input">
                    <div class="label">Wallet</div>
                    <input type="text" v-model="address" disabled>
                </div>
                <div class="input">
                    <div class="label">Amount</div>
                    <input type="number" v-model="amount" placeholder="Enter amount">
                </div>
                <div class="input">
                    <div class="label">Withdrawal Code</div>
                    <input type="text" v-model="code" placeholder="Enter withdrawal code">
                </div>
            </div>
            <div class="foot">
                <loading :height="'34px'" v-if="loading"/>
                <div class="btn" @click="withdraw()" v-else>Withdraw</div>
            </div>
        </div>
    </div>
</template>

<script>
import loading from '../../loading.vue'

export default {
    components: {
        loading
    },
    props: ['wallet', 'balance'],
    data() {
        return {
            loading: false,
            address: '',
            amount: '',
            code: ''
        }
    },
    mounted() {
        this.address = this.wallet
        if (this.wallet == '') {
            this.address = 'Add your wallet address from the settings'
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        withdraw() {
            if (this.wallet == '') {
                this.wallet_err(); return
            }
            if (this.amount == '' || this.code == '') {
                this.empty(); return
            }
            if (this.amount > this.balance) {
                this.amount_err(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('amount', this.amount)
            form.append('code', this.code)

            this.$http.post('/user/withdraw', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$emit('new_tx', this.amount)
                } else {
                    if (r.code) {
                        this.code_err()
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        wallet_err: {
            type: 'warn',
            title: '',
            message: 'You have not linked your wallet'
        },
        empty: {
            type: 'warn',
            title: '',
            message: 'Please enter amount and withdrawal code'
        },
        amount_err: {
            type: 'warn',
            title: '',
            message: 'Amount cannot be greater than your balance'
        },
        code_err: {
            type: 'warn',
            title: '',
            message: 'The code you entered is incorrect'
        }
    }
}
</script>

<style scoped>

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 350px;
        max-height: 80vh;
        overflow: auto;
        border-radius: 5px;

        position: relative;
        z-index: 4;
    }
    .title {
        font-weight: 600;
        padding: 20px;
    }
    .main {
        border-top: 1px solid #ddd;
        padding: 20px;
        padding-top: 0px;
    }


    .input {
        margin-top: 20px;
        position: relative
    }
    .label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
    }
    input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }





    .foot {
        border-top: 1px solid #ddd;
        padding: 20px;
    }
    .btn {
        padding: 8px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }
</style>