<template>
    <div class="banner">
        <div class="title">My Wallet</div>
        <div class="balance">{{balance}} ETH</div>
        <div class="opts">
            <div class="btn" @click="withdraw()">Withdraw</div>
            <div class="btn" @click="show_tx = true">Transactions</div>
        </div>

        <transactions @close="show_tx = false" v-if="show_tx"/>

        <withdraw @close="show_withdraw = false" @new_tx="new_tx($event)" v-if="show_withdraw"
            :wallet="wallet" :balance="balance"/>
    </div>
</template>

<script>
import transactions from './transactions.vue';
import withdraw from './withdraw.vue';

export default {
    components: {
        transactions, withdraw
    },
    props: ['balance', 'wallet'],
    data() {
        return {
            show_tx: false,
            show_withdraw: false
        }
    },
    methods: {
        withdraw() {
            if (this.balance == 0) {
                this.withdraw_not(); return
            }
            this.show_withdraw = true
        },
        new_tx(amount) {
            this.success()

            this.balance -= amount
            this.show_withdraw = false
        }
    },
    notifications: {
        withdraw_not: {
            type: 'warn',
            title: '',
            message: 'Your balance is currently zero'
        },
        transactions_not: {
            type: 'warn',
            title: '',
            message: 'You have not made any transactions'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Your withdrawal is been processed'
        }
    }
}
</script>

<style scoped>
    .banner {
        background-color: white;
        background: linear-gradient(to right, var(--main), lightgreen);
        padding: 20px;
        border-radius: 5px;
    }
    .title {
        font-weight: 600;
        font-size: 14px;
        color: white;
    }
    .balance {
        text-align: center;
        padding: 30px 20px;
        font-size: 25px;
        font-weight: 600;
        color: white;
    }
    .opts {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
    }
    .btn {
        padding: 8px 30px;
        font-size: 14px;
        font-weight: 500;
        background-color: white;
        color: var(--main);
        cursor: pointer;
        border-radius: 5px;
    }
</style>