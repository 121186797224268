<template>
    <div>
        <headerlayout class="h"/>
        <div class="main">
            <div class="box">
                <div class="title">Login</div>
                <div class="input">
                    <div class="label" v-if="email !== ''">Email</div>
                    <input type="text" placeholder="Enter your email" v-model="email">
                </div>
                <div class="input">
                    <div class="label" v-if="password !== ''">Password</div>
                    <input type="password" placeholder="Enter your password" v-model="password">
                    
                    <router-link to="/forgot_pass">
                        <div class="small">Forgot Password?</div>
                    </router-link>
                </div>

                <loading v-if="loading" :height="'39px'"/>
                <div class="btn" v-else @click="login()">Login</div>

                <router-link to="/signup">
                    <div class="text">Don't have an account? <span>Signup</span></div>
                </router-link>
            </div>
        </div>
        <footerlayout/>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('digivaultmarket')

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

import loading from '../components/loading.vue'

export default {
    components: {
        headerlayout, footerlayout,
        loading
    },
    data() {
        return {
            email: '',
            password: '',
            loading: false
        }
    },
    mounted() {
        window.scroll(0, 0)

        db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$root.$data.login = false
            } else {
                this.$router.push('/')
            }
        })
    },
    methods: {
        login() {
            if (this.email == '' || this.password == '') {
                this.empty()
            }

            this.loading = true

            let form = new FormData()
            form.append('email', this.email)
            form.append('password', this.password)

            this.$http.post('/login', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    
                    // this.$crisp.push(["do", "chat:show"])

                    this.success()

                    db.collection('auth').add({
                        'id': 1,
                        'key': r.session,
                        'created': r.created,
                        'user_id': r.user_id,
                        'username': r.username,
                        'firstname': r.firstname,
                        'lastname': r.lastname,
                        'wallet': r.wallet,
                        'dp': r.dp,
                        'cover': r.cover,
                        'email': r.email,
                        'email_verified': r.email_verified
                    }).then(() => {
                        
                        this.$root.$data.login = true
                        this.$http.defaults.headers.common['Auth'] = r.session

                        if (r.email_verified) {
                            this.$root.$data.email = this.email
                            this.$router.push('/dashboard')
                        } else {
                            this.$root.$data.email = this.email
                            this.$router.push('/verify')
                        }
                    })
                } else {
                    if (r.email) {
                        this.email_err()
                    }
                    if (r.password) {
                        this.password_err()
                    }
                }

                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Some fields are empty'
        },
        email_err: {
            type: 'warn',
            title: '',
            message: 'This email has not been registered'
        },
        password_err: {
            type: 'warn',
            title: '',
            message: 'Incorrect password'
        },

        success: {
            type: 'success',
            title: '',
            message: 'Login success'
        }
    }
}
</script>

<style scoped>

    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .main {
        min-height: 100vh;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f3;
    }

    .box {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        width: 400px;
        padding: 25px;
        border-radius: 10px;
    }
    .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 30px;
        text-align: center;
    }
    .input {
        margin-bottom: 30px;
        outline: none;
        position: relative;
    }
    .label {
        position: absolute;
        top: -8px;
        left: 10px;
        background-color: white;
        font-weight: 600;
        padding: 0px 5px;
        font-size: 14px;
    }
    input {
        padding: 15px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .small {
        text-align: right;
        font-weight: 500;
        font-size: 14px;
        color: var(--main);
        cursor: pointer;
    }
    .btn {
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        padding: 10px;
        cursor: pointer;
        text-align: center;
        font-weight: 600;
    }

    .text {
        font-weight: 500;
        margin-top: 30px;
        font-size: 14px;
        text-align: center;
    }
    span {
        color: var(--main);
    }
</style>