import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import about from '../views/About.vue'
import terms from '../views/terms.vue'
import login from '../views/login.vue'
import signup from '../views/signup'
import forgot_pass from '../views/forgot_pass.vue'
import verify from '../views/verify.vue'

import search from '../views/search.vue'

import art from '../views/art.vue'
import user from '../views/user.vue'
import collection from '../views/collection.vue'

import marketplace from '../views/marketplace.vue'
import checkout from '../views/checkout.vue'

import Dashboardview from '../views/dashboard/view.vue'
import dashboard from '../views/dashboard/dashboard.vue'
import collections from '../views/dashboard/collections.vue'
import orders from '../views/dashboard/orders.vue'
import sales from '../views/dashboard/sales.vue'
import settings from '../views/dashboard/settings.vue'


import admin_login from '../views/admin/login.vue'

import admin from '../views/admin/admin.vue'
import admin_sales from '../views/admin/sales.vue'
import admin_users from '../views/admin/users.vue'
import admin_arts from '../views/admin/arts.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'about',
        component: about
    },
    {
        path: '/terms',
        name: 'terms',
        component: terms
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/signup',
        name: 'signup',
        component: signup
    },
    {
        path: '/forgot_pass',
        name: 'forgot_pass',
        component: forgot_pass
    },
    {
        path: '/verify',
        name: 'verify',
        component: verify
    },
    {
        path: '/search',
        name: 'search',
        component: search
    },
    {
        path: '/art/:id',
        name: 'art',
        component: art
    },
    {
        path: '/user/:id',
        name: 'user',
        component: user
    },
    {
        path: '/collection/:url',
        name: 'collection',
        component: collection
    },
    {
        path: '/explore',
        name: 'marketplace',
        component: marketplace
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: checkout
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboardview,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: dashboard
            },
            {
                path: 'collections',
                name: 'collections',
                component: collections
            },
            {
                path: 'orders',
                name: 'orders',
                component: orders
            },
            {
                path: 'sales',
                name: 'sales',
                component: sales
            },
            {
                path: 'settings',
                name: 'settings',
                component: settings
            }
        ]
    },
    {
        path: '/admin/login',
        name: 'admin_login',
        component: admin_login
    },
    {
        path: '/admin',
        name: 'admin',
        component: admin,
        children: [
            {
                path: '',
                name: 'sales',
                component: admin_sales
            },
            {
                path: 'users',
                name: 'users',
                component: admin_users
            },
            {
                path: 'arts',
                name: 'arts',
                component: admin_arts
            }
        ]
    }
]

const router = new VueRouter({
  routes
})

export default router
