<template>
    <div class="view">
        <headerlayout class="h"/>
        <div class="grid">
            <menulayout class="web"/>
            <div class="main">
                <router-view v-if="ready"></router-view>
            </div>
            <mobile class="m"/>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('digivaultmarket')

import headerlayout from '../../components/util/header.vue'
import menulayout from '../../components/dashboard/menu.vue';
import mobile from '../../components/dashboard/mobile.vue';

export default {
    components: {
        headerlayout,
        menulayout, mobile
    },
    data() {
        return {
            ready: false
        }
    },
    mounted() {

        db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$router.push('/login')
                this.$root.$data.login = false
            } else {
                if (!document.email_verified) {
                    this.$router.push('/verify')
                }
            }

            this.ready = true
        })
    }
}
</script>

<style scoped>
    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .grid {
        display: grid;
        grid-template-columns: 300px auto;
        /* width: 100vw; */
        height: calc(100vh - 71px);
    }
    .main {
        height: 100%;
        overflow: auto;
        background-color: #f1f1f3;
    }

    .m {
        display: none;
    }


    @media screen and (max-width: 750px) {
        .grid {
            grid-template-columns: auto;
            grid-template-rows: auto max-content;
        }
        .web {
            display: none;
        }
        .m {
            display: flex;
        }
    }
</style>