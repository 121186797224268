<template>
    <div class="err_popup">
        <div class="dark"></div>
        <div class="box">
            <div class="head">
                <div class="title">Transaction Declined</div>
            </div>
            <div class="main">
                <div class="text">
                    Hello, we're sorry, we are currently unable to process this transaction,
                    Our system has detected possible fault from the seller.
                </div>
                <div class="text">
                    In situation like this, digivaultmarket got you covered, this art has been marked as semi
                    purchased and won't show on the marketplace until this issue is resolved. View your
                    orders to follow up with the update from us.
                </div>
                <div class="flex">
                    <div class="label">Status:</div>
                    <div class="value">Buy Pending</div>
                </div>
                <div class="flex">
                    <div class="label">Order Id:</div>
                    <div class="value">{{order_id}}</div>
                </div>
                <!-- <div class="flex">
                    <div class="label">Date:</div>
                    <div class="value">{{d}}</div>
                </div> -->
            </div>

            <router-link to="/dashboard/orders">
                <div class="click">View Orders</div>
            </router-link>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: ['order_id', 'date'],
    data() {
        return {
            d: ''
        }
    },
    mounted() {
        this.d = moment(this.date).format('DD-MM-YYYY HH:mm')
    }
}
</script>

<style scoped>

    .err_popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        width: 300px;
        border-radius: 5px;

        position: relative;
        z-index: 4;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-bottom: 1px solid #ddd;
    }
    .title {
        font-weight: 600;
    }
    .main {
        padding: 15px;
    }
    .text {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .flex {
        display: flex;
        gap: 5px;
        font-size: 14px;
        margin-top: 15px;
    }
    .value {
        font-weight: 500;
    }

    .click {
        padding: 10px;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        border-top: 1px solid #ddd;
        color: var(--main);
        cursor: pointer;
    }
</style>