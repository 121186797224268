<template>
    <div class="art">
        <div class="f" @click="show_main = !show_main">
            <div class="icon" :class="[show_main ? 'rot' : '']">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            
            <div class="fle">
                <div class="l">Title:</div>
                <div class="v">{{ art.title }}</div>
            </div>
            <div class="fle">
                <div class="l">Added:</div>
                <div class="v">{{ art.created }}</div>
            </div>
        </div>
        <div class="main" v-if="show_main">

            <div class="grid">
                <div class="image">
                    <img :src="art.image"/>
                </div>
                <div class="info">
                    <div class="title">{{ art.title }}</div>
                    <div class="flex">
                        <div class="label">Category:</div>
                        <div class="value">{{ art.category }}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Added:</div>
                        <div class="value">{{ art.created }}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Price:</div>
                        <div class="value">{{ art.price }} {{ art.currency }}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Sold:</div>
                        <div class="value">{{ art.sold ? 'Yes' : 'No' }}</div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="click del" @click="show_del = true">Delete</div>
                <div class="click" @click="copy()">Copy link</div>
                <router-link :to="'/art/' + art.art_id">
                    <div class="click">View art</div>
                </router-link>
            </div>
        </div>

        <delete_pop v-if="show_del" :art="art" @close="show_del = false"/>
    </div>
</template>

<script>
import arrow from '../../../icons/arrow.vue';
import delete_pop from './delete.vue'

export default {
    components: {
        arrow, delete_pop
    },
    props: ['art'],
    data() {
        return {
            show_main: false,
            show_del: false
        }
    },
    methods: {
        copy() {
            let vm = this
            navigator.clipboard.writeText('digivaultmarket.com/#/art/' + this.art.art_id).then(function() {
                vm.copied()
            })
        }
    },
    notifications: {
        copied: {
            type: 'success',
            title: '',
            message: 'Art link copied'
        }
    }
}
</script>

<style scoped>


    .art {
        background-color: white;
        margin-top: 20px;
        border-radius: 5px;
        overflow: hidden;
    }
    .f {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 15px;
        overflow: hidden;
    }
    .fle {
        display: flex;
        gap: 5px;
    }
    .l {
        color: gray;
    }
    .v {
        font-weight: 500;
    }
    .icon {
        background-color: var(--main);
        fill: white;
        height: 40px;
        width: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s;
    }
    .rot {
        transform: rotate(90deg);
    }


    .grid {
        display: grid;
        grid-template-columns: 30% auto;
        border-top: 1px solid #ddd;
    }
    .image {
        height: 150px;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f3;
    }
    .image img {
        width: 100%;
    }
    .info {
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 600;
    }
    .flex {
        display: flex;
        margin-top: 4px;
        font-size: 14px;
        gap: 10px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }


    
    .bottom {
        display: flex;
        justify-content: space-between;
        /* grid-template-columns: 33.33% 33.33% 33.33%; */
        border-top: 1px solid #ddd;
    }
    .click {
        padding: 10px 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: var(--main);
        cursor: pointer;
    }
    .del {
        color: tomato;
    }
</style>