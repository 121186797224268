<template>
    <div class="forgot_pass">
        <headerlayout class="h"/>
        
        <div class="main">
            <div class="box">
                <div class="title">Forgot Password</div>
                <div class="text">Enter your email address then click on get code to verify your email</div>
            
                <div class="input">
                    <div class="label">Email</div>
                    <input type="text" placeholder="Enter your registered email address" v-model="email">
                </div>
                <div class="input">
                    <div class="label">Verification Code</div>
                    <div class="grid">
                        <input type="text" placeholder="Enter the code sent your email" v-model="code">
                        <div class="click" @click="get_code()">{{text}}</div>
                    </div>
                </div>
                <div class="input">
                    <div class="label">Password</div>
                    <input type="password" placeholder="Enter new password" v-model="password">
                </div>
                <div class="input">
                    <div class="label">Re - enter password</div>
                    <input type="password" placeholder="Re - enter new password" v-model="password2">
                </div>

                <loading v-if="loading" :height="'39px'"/>
                <div class="btn" v-else @click="submit()">Submit</div>

                <div class="changed" v-if="password_changed">
                    Success: Your password has been updated, please login
                </div>
            </div>
        </div>

        <footerlayout/>
    </div>
</template>

<script>
// import Localbase from 'localbase'
// let db = new Localbase('digivaultmarket')

import headerlayout from '../components/util/header.vue'
import footerlayout from '../components/util/footer.vue';

import loading from '../components/loading.vue';

export default {

    components: {
        headerlayout, footerlayout,
        loading
    },
    data() {
        return {
            email: '',
            code: '',
            password: '',
            password2: '',

            text: 'Get Code',

            password_changed: false,
            loading: false
        }
    },
    mounted() {
        window.scroll(0, 0)
    },
    methods: {
        get_code() {
            if (this.email == '') {
                this.empty(); return 
            }

            if (this.text == 'Sending...') {return }

            this.text = 'Sending...'
            let form = new FormData()
            form.append('email', this.email)

            this.$http.post('/get_email_code', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.email_sent()
                } else {
                    this.not_found()
                }
                this.text = 'Get Code'
            })
        },
        submit() {
            if (this.email == '' || this.code == '' || this.password == '' || this.password2 == '') {
                this.typee(); return
            }

            if (this.password != this.password2) {
                this.password_check(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('email', this.email)
            form.append('code', this.code)
            form.append('password', this.password)

            this.$http.post('/change_password', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.password_changed = true

                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 3000);
                    
                    this.success()
                } else {
                    if (r.email) {
                        this.not_found()
                    }
                    if (r.code) {
                        this.wrong_code()
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        email_sent: {
            type: 'success',
            title: '',
            message: 'Email verification code sent'
        },
        not_found: {
            type: 'warn',
            title: '',
            message: 'This email address is not registered'
        },
        empty: {
            type: 'warn',
            title: '',
            message: 'Please enter your email address'
        },
        typee: {
            type: 'warn',
            title: '',
            message: 'please enter your email, verification code and password'
        },
        password_check: {
            type: 'warn',
            title: '',
            message: 'your password do not match, re-enter password'
        },
        wrong_code: {
            type: 'warn',
            title: '',
            message: 'The email code you entered is incorrect'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Your password has been updated, please login'
        }
    }
}
</script>

<style scoped>


    .h {
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .main {
        height: 100vh;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f3;
    }

    .box {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        width: 400px;
        padding: 25px;
        border-radius: 10px;
    }
    .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px;
    }
    .text {
        /* text-align: center; */
        margin-bottom: 30px;
        font-size: 14px;
        margin-bottom: 30px;
    }
    .input {
        margin-bottom: 30px;
        outline: none;
        position: relative;
    }
    .label {
        position: absolute;
        top: -8px;
        left: 10px;
        background-color: white;
        font-weight: 600;
        padding: 0px 5px;
        font-size: 14px;
    }
    input, select{
        padding: 15px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
    }
    
    .grid {
        display: grid;
        align-items: center;
        grid-template-columns: auto max-content;
    }
    .click {
        padding: 10px;
        color: var(--main);
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
    }
    .btn {
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        padding: 10px;
        cursor: pointer;
        text-align: center;
        font-weight: 600;
    }


    .changed {
        background-color: #f1f1f3;
        padding: 10px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 5px;
        margin-top: 30px;
    }
</style>