<template>
    <div class="pop">
        <div class="dark" @click="close()"></div>
        <transition name="in">
            <div class="mobile_menu" v-if="menu">
                <div class="close" @click="close()">
                    <close :size="'30px'"/>
                </div>
                <router-link to="/about">
                    <div class="i first" @click="close()">About</div>
                </router-link>

                <router-link to="/explore">
                    <div class="i" @click="close()">Explore NFT's</div>
                </router-link>

                <!-- <router-link to="/">
                    <div class="i" @click="close()">Resources</div>
                </router-link> -->

                <router-link to="/dashboard" v-if="login">
                    <div class="i" @click="close()">Dashboard</div>
                </router-link>
                <router-link to="/dashboard/collections" v-if="login">
                    <div class="i" @click="close()">Collections</div>
                </router-link>
                <router-link to="/dashboard/sales" v-if="login">
                    <div class="i" @click="close()">Sales</div>
                </router-link>
                <router-link to="/dashboard/orders" v-if="login">
                    <div class="i" @click="close()">Orders</div>
                </router-link>
                <div class="i" v-if="login" @click="logout()">
                    Logout
                </div>

                <router-link to="/login" v-if="!login">
                    <div class="i" @click="close()">Login</div>
                </router-link>
                <router-link to="/signup" v-if="!login">
                    <div class="i" @click="close()">Signup</div>
                </router-link>
            </div>
        </transition>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('digivaultmarket')

import close from '../../icons/close.vue';

export default {
    components: {
        close
    },
    computed: {
        login() {
            return this.$root.$data.login
        }
    },
    mounted() {
        setTimeout(() => {
            this.menu = true
        }, 100);
    },
    data() {
        return {
            menu: false
        }
    },
    methods: {
        
        close() {
            this.menu = false
            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },
        logout() {
            
            // this.$crisp.push(["do", "chat:hide"])
            
            db.collection('auth').doc({ id: 1 }).delete()
            this.$root.$data.login = false
            
            this.$http.defaults.headers.common['Auth'] = ''
            localStorage.clear()
            this.$router.go()
        }
    }
}
</script>

<style scoped>


    .pop {
        position: fixed;
        z-index: 6;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        color: white;
        fill: white;
    }
    .dark {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
    }
    .close {
        width: fit-content;
        margin-left: auto;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 40px;
        cursor: pointer;
        /* opacity: .7; */
    }
    .mobile_menu {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 250px;
        height: 100%;
        background-color: #191919;
        z-index: 3;
    }

    .in-enter-active {
        animation: move .3s;
    }
    .in-leave-active {
        animation: move .2s reverse;
    }

    @keyframes move {
        from {
            margin-right: -250px;
        }
        to {
            margin-right: 0px;
        }
    }
    .i {
        padding: 15px;
        border-top: 1px solid #3a3a3a;
        cursor: pointer;
        font-size: 14px;
        opacity: .7;
    }
    .first {
        border-top: none;
    }


</style>