<template>
    <div class="admin">
        <div class="head">
            <div class="icon" @click="show_pop = true">
                <menuicon :size="'30px'"/>
            </div>
            <div class="title">digivaultmarket Admin</div>
            <div class="btn" @click="logout()">Logout</div>
        </div>
        <div class="grid">
            <menulayout class="m"/>
            <div class="main">
                <router-view v-if="ready"></router-view>
            </div>
        </div>


        <div class="popup" v-if="show_pop">
            <div class="dark" @click="show_pop = false"></div>
            <menulayout class="mobile"/>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('digivaultmarket')

import menuicon from '../../icons/menu.vue';

import menulayout from '../../components/admin/menu.vue';

export default {
    components: {
        menuicon,
        menulayout
    },
    data() {
        return {
            ready: false,
            show_pop: false
        }
    },
    // beforeCreate() {
    //     this.$OneSignal.showSlidedownPrompt()
    // },
    methods: {
        logout() {
            
            db.collection('auth').doc({ id: 2 }).delete()
            this.$router.push('/admin/login')
        }
    },
    mounted() {

        db.collection('auth').doc({ id: 2 }).get().then(document => {
            if (document == undefined) {
                this.$router.push('/admin/login')
                this.$root.$data.login = false
            } else {
                this.$http.defaults.headers.common['Auth'] = document.key
                this.ready = true
            }

            this.ready = true
        })
    }
}
</script>

<style scoped>
    .head {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid var(--main);
    }
    .icon {
        display: none;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        color: var(--main);
    }
    .btn {
        cursor: pointer;
        padding: 8px 30px;
        font-size: 14px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        font-weight: 500;
    }
    .grid {
        display: grid;
        grid-template-columns: 300px auto;
        /* width: 100vw; */
        height: calc(100vh - 76px);
    }
    .main {
        height: 100%;
        overflow: auto;
        background-color: #f1f1f3;
    }


    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        display: flex;

        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .mobile {
        position: relative;
        z-index: 5;
        background-color: white;
        width: 200px;
    }



    @media screen and (max-width: 800px) {
        .icon {
            display: block;
            fill: var(--main);
            cursor: pointer;
        }

        .grid {
            grid-template-columns: auto;
        }
        .m {
            display: none;
        }
    }
</style>