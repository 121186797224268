<template>
    <div class="art">
        <div class="grid">
            <div class="image">
                <img :src="art.image"/>
            </div>
            <div class="info">
                <div class="title">{{ art.title }}</div>
                <div class="flex">
                    <div class="label">Category:</div>
                    <div class="value">{{ art.category }}</div>
                </div>
                <div class="flex">
                    <div class="label">Added:</div>
                    <div class="value">{{ added }}</div>
                </div>
                <div class="flex">
                    <div class="label">Price:</div>
                    <div class="value">{{ art.price }} {{ art.currency }}</div>
                </div>
                <div class="flex">
                    <div class="label">Status:</div>
                    <div class="value">{{ art.status }}</div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="click" @click="show_edit = true">Edit</div>
            <div class="click" @click="copy()">Copy link</div>
            <router-link :to="'/art/' + art.art_id">
                <div class="click">View art</div>
            </router-link>
        </div>

        <art_edit v-if="show_edit" @close="show_edit = false" :art="art"/>
    </div>
</template>

<script>
import moment from 'moment'

import art_edit from './art_edit.vue'

export default {
    components: {
        art_edit
    },
    props: ['art'],
    mounted() {
        this.added = moment(this.art.created).format('Do MMM YYYY')
    },
    data() {
        return {
            added: '',
            show_edit: false
        }
    },
    methods: {
        copy() {
            let vm = this
            navigator.clipboard.writeText('digivaultmarket.com/#/art/' + this.art.art_id).then(function() {
                vm.copied()
            })
        }
    },
    notifications: {
        copied: {
            type: 'success',
            title: '',
            message: 'Art link copied'
        }
    }
}
</script>

<style scoped>
    .art {
        margin-top: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;
    }
    .grid {
        display: grid;
        grid-template-columns: 30% auto;
    }
    .image {
        height: 150px;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f3;
    }
    .image img {
        width: 100%;
    }
    .info {
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 600;
    }
    .flex {
        display: flex;
        margin-top: 4px;
        font-size: 14px;
        gap: 10px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        /* grid-template-columns: 33.33% 33.33% 33.33%; */
        border-top: 1px solid #ddd;
    }
    .click {
        padding: 10px 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: var(--main);
        cursor: pointer;
    }
</style>