<template>
    <div id="app" :style="{'--main': c.main, '--trans': c.trans}">
        <router-view v-if="ready"/>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('digivaultmarket')

export default {
    
    computed: {
        c() {
            return this.$root.$data.colors
        },
    },
    data() {
        return {
            ready: false
        }
    },

    created() {
        document.title = 'digivaultmarket'
        
        // this.$crisp.$on('loaded', () => {
        //     console.log('crisp chat loaded')

        //     setTimeout(() => {
        //         db.collection('auth').doc({ id: 1 }).get().then(document => {
        //             if (document == undefined) {
        //                 this.$crisp.push(["do", "chat:hide"])
        //             } else {
        //                 this.$crisp.push(["do", "chat:show"])
        //             }
        //         })
        //     }, 2000);
            
        // })

    },
    
    mounted() {

        db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$root.$data.login = false
                
            } else {

                this.$root.$data.login = true
                this.$root.$data.email = document.email
                this.$root.$data.user_id = document.user_id
                this.$root.$data.username = document.username
                
                this.$http.defaults.headers.common['Auth'] = document.key
                

                // this.$tawkMessenger.$on('load', () => {
                //     this.$tawkMessenger.setAttributes({
                //         name : document.firstname + ' ' + document.lastname,
                //         email : document.email,
                //         username: document.username
                //     });
                // });
            }
            this.ready = true
        })

        let cart = JSON.parse(localStorage.getItem('cart'))

        this.$root.$data.cart = cart == null ? [] : cart
    }
}
</script>

<style>

@import 'assets/css/swiper.css';

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('/assets/fonts/montserrat.ttf');
}
body {
    padding: 0px;
    margin: 0px;
}
#app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;

}

a {
    color: inherit;
    text-decoration: none;
}

textarea, input, select {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    resize: none;
    font-weight: 500;
}



.swiper-pagination-bullet-active {
    background: var(--main) !important;
}
</style>
