<template>
    <div class="login">
        <router-link to="/dashboard">
            <div class="click">Dashboard</div>
        </router-link>
        
        <router-link to="/dashboard/collections">
            <div class="click">Collections</div>
        </router-link>

        <router-link to="/dashboard/sales">
            <div class="click">Sales</div>
        </router-link>
        
        <router-link to="/dashboard/orders">
            <div class="click">Orders</div>
        </router-link>

        <div class="click">Settings</div>
        <div class="click" @click="logout()">Logout</div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('digivaultmarket')

export default {
    methods: {
        logout() {
            
            // this.$crisp.push(["do", "chat:hide"])
            
            db.collection('auth').doc({ id: 1 }).delete()
            this.$root.$data.login = false
            
            this.$http.defaults.headers.common['Auth'] = ''
            localStorage.clear()
            this.$router.go()
        }
    }
}
</script>

<style scoped>
    .login {
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        width: 200px;
        background-color: white;
        overflow: hidden;
    }
    .click {
        padding: 15px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
    }
</style>