<template>
    <div class="banner_c">
        <div class="banner">
            <div>
                <div class="name">digivault market</div>
                <div class="big">
                    Discover Your Next Masterpiece: It's time to Invest in NFTs
                </div>
                <!-- <div class="text">
                    Welcome to digivaultmarket, where creativity meets technology. 
                    Our platform offers a seamless experience for both artists and buyers to connect and 
                    transact with ease, using cryptocurrency as a form of payment.
                </div>
                <div class="text hide">
                    As an artist, you can showcase your art to a global audience, and receive secure 
                    payments in cryptocurrency. As a buyer, you can browse a vast collection of art from 
                    various artists and genres, and purchase with confidence, knowing that your transaction 
                    is secure and transparent.
                </div> -->
                <div class="text">
                    Explore, collect, and own exclusive digital treasures in our NFT marketplace. 
                    Dive into the world of non-fungible tokens and discover unique artworks, collectibles, 
                    and digital assets from talented creators. Start your journey today and join the revolution 
                    of digital ownership. Your next masterpiece awaits!
                </div>
            </div>
            <div class="hide">
                <img src="../../assets/images/nft.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .banner_c {
        padding: 100px 20px;
        padding-top: 50px;
    }
    .banner {
        /* background: linear-gradient(to top right, var(--main), rgb(234, 76, 137)); */
        /* padding: 50px 50px; */
        max-width: 1100px;
        margin: 0 auto;
        /* border: 1px solid #ddd; */
        /* margin: 30px; */
        border-radius: 10px;
        padding: 30px 0px;
        box-sizing: border-box;
        border-bottom: 5px solid var(--main);
        /* color: white; */

        display: flex;
        align-items: center;
        gap: 50px;
        /* height: 70vh; */
    }

    .name {
        font-size: 50px;
        font-weight: 200;
        margin-bottom: 20px;
    }

    .big {
        font-size: 20px;
        font-weight: 800;
        /* color: var(--main); */
    }
    .text {
        margin-top: 20px;
        font-size: 14px;
        line-height: 25px;
        /* font-weight: 500; */
    }

    img {
        width: 400px;
    }

    @media screen and (max-width: 1040px) {
        .banner {
            /* margin: 30px 20px; */
        }
    }

    @media screen and (max-width: 850px) {
        .hide {
            /* display: none; */
        }
        
        .name {
            font-size: 35px;
        }
        .banner_c {
            padding: 50px 0px;
        }
        .banner {
            flex-wrap: wrap;
            padding: 30px;
        }
        .big {
            font-weight: 700;
        }
        
        img {
            width: 100%;
        }
    }
</style>