<template>
    <div class="main">
        <div class="box">
            <div class="title">Admin Login</div>
            <div class="input">
                <div class="label">Username</div>
                <input type="text" placeholder="Enter username" v-model="username">
            </div>
            <div class="input">
                <div class="label">Password</div>
                <input type="password" placeholder="Enter password" v-model="password">
            </div>

            <loading v-if="loading" :height="'34px'"/>
            <div class="btn" @click="login()" v-else>Login</div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('digivaultmarket')

import loading from '../../components/loading.vue'

export default {
    components: {
        loading
    },
    data() {
        return {
            username: '',
            password: '',

            loading: false
        }
    },
    mounted() {
        window.scroll(0, 0)

        db.collection('auth').doc({ id: 2 }).get().then(document => {
            if (document == undefined) {
                console.log('login');
            } else {
                this.$router.push('/admin')
            }
        })
    },
    methods: {
        login() {
            this.loading = true
            let form = new FormData()
            form.append('username', this.username)
            form.append('password', this.password)

            this.$http.post('/admin/login', form)
            .then(data => {
                let r = data.data
                if (r.success) {

                    db.collection('auth').add({
                        'id': 2,
                        'key': r.session
                    }).then( () => {
                        this.$router.push('/admin')
                        this.loading = false
                    })
                } else {
                    this.login_err()
                }

                this.loading = false
            })
        }
    },
    notifications: {
        login_err: {
            type: 'warn',
            title: '',
            message: 'Incorrect username or password'
        }
    }
}
</script>

<style scoped>
    .main {
        background-color: #f1f1f3;
        width: 100vw;
        height: 100vh;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box {
        background-color: white;
        width: 300px;
        padding: 20px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .input {
        margin-bottom: 20px;
        position: relative
    }
    .label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
    }
    input, textarea, select {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }
</style>