<template>
    <div class="header">
        <div class="web">
            <router-link to="/">
                <div class="logo">
                    digivaultmarket
                </div>
            </router-link>



            <div class="flex">
                <router-link to="/about">
                    <div class="click">About</div>
                </router-link>
                <router-link to="/explore">
                    <div class="click">Explore NFT's</div>
                </router-link>
            </div>
            <div class="flex">
                <router-link to="/search">
                    <div>
                        <search :size="'30px'"/>
                    </div>
                </router-link>

                <div class="icon">
                    <div @click="show_login = !show_login">
                        <user :size="'30px'"/>
                    </div>
                    <div v-if="show_login">
                        <loggedin class="popup" v-if="login"/>
                        <login class="popup" v-else/>
                    </div>
                </div>

                <div class="icon" @click="show_cart = true">
                    <cart :size="'30px'"/>
                    <div class="no">{{cart.length}}</div>
                </div>
            </div>
        </div>
        <div class="mobile">
            
            <router-link to="/">
                <div class="logo">
                    digivaultmarket
                </div>
            </router-link>
            
            <div class="mobile_icons">
                <router-link to="/search">
                    <div class="mobile_icon">
                        <search :size="'30px'"/>
                    </div>
                </router-link>

                <div class="mobile_icon" @click="user_clicked()">
                    <user :size="'30px'"/>
                </div>
                <div class="mobile_icon" @click="show_cart = true">
                    <cart :size="'30px'"/>
                    <div class="no">{{cart.length}}</div>
                </div>
                <div class="mobile_icon" @click="show_mobile_menu = true">
                    <menuicon :size="'30px'"/>
                </div>
            </div>

            <mobilemenu v-if="show_mobile_menu" @close="show_mobile_menu = false"/>
        </div>

        <cartbox v-if="show_cart" @close="show_cart = false"/>
    </div>
</template>

<script>
import search from '../../icons/search.vue';
import user from '../../icons/user.vue'
import cart from '../../icons/cart.vue';
import menuicon from '../../icons/menu.vue'

import login from '../popup/login.vue';
import loggedin from '../popup/loggedin.vue';

import mobilemenu from '../popup/menu.vue';

import cartbox from '../popup/cart.vue';

export default {
    components: {
        search, user, cart, menuicon,
        login, loggedin,
        mobilemenu,

        cartbox
    },
    computed: {
        login() {
            return this.$root.$data.login
        },
        cart() {
            return this.$root.$data.cart
        }
    },
    data() {
        return {
            show_login: false,
            show_mobile_menu: false,
            show_cart: false
        }
    },
    methods: {
        user_clicked() {
            if (this.login) {
                if (this.$route.name == 'dashboard') { return }
                this.$router.push('/dashboard')
            } else {
                if (this.$route.name == 'login') { return }
                this.$router.push('/login')
            }
        }
    }
}
</script>

<style scoped>
    .web {
        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: center;
        /* justify-content: center; */
        padding: 20px 30px;
        gap: 50px;
        background-color: white;
        border-bottom: 1px solid #ddd;
    }
    .logo {
        font-size: 25px;
        font-weight: 1000;
        color: var(--main);
    }

    .flex {
        display: flex;
        align-items: center;
        gap: 30px;
    }
    .click {
        font-weight: 600;
        cursor: pointer;
    }
    .icon {
        cursor: pointer;
        position: relative;
    }
    .no {
        position: absolute;
        top: -7px;
        right: -7px;
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        width: 20px;
        height: 20px;
        border-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: var(--main);
        color: white;
    }
    .popup {
        position: absolute;
        top: 40px;
        right: -10px;
    }

    .mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        padding: 17px 20px;
        background-color: white;
        /* border-bottom: 1px solid #ddd; */
    }
    .mobile_icons {
        display: flex;
        gap: 20px;
    }
    .mobile_icon {
        position: relative;
        cursor: pointer;
    }


    @media screen and (max-width: 900px) {
        .web {
            gap: 30px;
        }
        
        .logo {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 750px) {
        .mobile {
            display: flex;
        }
        .web {
            display: none;
        }
    }
</style>