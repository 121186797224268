import Vue from 'vue'
import App from './App.vue'
import router from './router'
import notify from './extras/notify'

// import OneSignalVue from 'onesignal-vue'
// Vue.use(OneSignalVue);



// import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';
// Vue.use(TawkMessengerVue, {
//     propertyId : '64775aea74285f0ec46ec099',
//     widgetId : '1h1p3m9p4',
// });


// import CrispChat from '@dansmaculotte/vue-crisp-chat'
// Vue.use(CrispChat, {
//     websiteId: 'f18effbd-ce40-4933-b283-eb01843cb7c8',
// })


import axios from 'axios'
import Localbase from 'localbase'

axios.interceptors.response.use(function (response) {
    let r = response.data
    if (r.login) {

        let db = new Localbase('digivaultmarket')
        db.collection('auth').doc({ id: 1 }).delete()
        localStorage.clear()

        window.location.reload();

    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

Vue.prototype.$http = axios

// axios.defaults.baseURL = 'http://localhost/nft/index.php/'
axios.defaults.baseURL = 'https://digivaultmarket.com/backend/index.php/'


Vue.config.productionTip = false

new Vue({
    router,
    notify,
    data() {
        return {
            colors: {
                main: 'rgb(234, 76, 137)',
                trans: 'rgba(234, 76, 137, 0.2)'
            },
            login: false,
            email: '',
            user_id: '',
            username: '',
            cart: []
        }
    },
    // beforeMount() {
    //     this.$OneSignal.init({ appId: '83903559-c615-4def-bf90-769819bfab56' });
    // },
    render: h => h(App)
}).$mount('#app')
